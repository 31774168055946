export const CAROUSAL_ITEMS=[
    {
      key: 1,
      src: './LoginImage1.png'
    },
    {
      
      key: 2,
      src: './LoginImage2.png'
    },
    {

      key: 3,
      src: './LoginImage3.png'
    },
    {

      key: 4,
      src: './LoginImage4.png'
    }
    
  ]